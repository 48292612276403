.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffffff;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

.carousel-img {
  -webkit-animation: zoom 20s;
  animation: zoom 20s;
}

img {
  pointer-events: none;
}

a {
  text-decoration: none !important;
  font-weight: 18px;
  font-size: 1em;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.navbar-dark .navbar-toggler-icon,
.navbar-dark .navbar-toggler-icon::before,
.navbar-dark .navbar-toggler-icon::after {
  background-color: var(--bs-gray-100);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon {
  transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon::before {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) .navbar-toggler-icon::after {
  transform: rotate(-90deg) translateX(7px);
}

.navbar-edit{
  background-color: #232729;
  position: fixed;
  width: 100%;
} 

.nav-link {
  color: white!important;
}

.nav-link--active {
  color: #db01d4 !important;
  transform: scale(1.1);
  font-weight: 10px;
}

.nav-link:hover {
  color: #db01d4 !important;
  transform: scale(1.1);
  text-decoration: none !important;
  font-weight: 18px;

}

.togglecolor{
  color:white !important;
  background-color: #db01d4 !important;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  background-color: #232729 !important;
  opacity: 0.8;
  text-decoration: none;
  color: #db01d4 !important;
  border:none;
}

.menudropdownhover:hover{
  margin-left: 10px;
  border: 0px;
  height: auto;
  background: transparent!important;

  color: #db01d4 !important;
}

.iconsociaux:hover{
  font-size: 40px;
}

.textombrenoir {
  text-shadow: -1px 1px 0 #000,
                          1px 1px 0 #000,
                         1px -1px 0 #000,
                        -1px -1px 0 #000;
}

.bnttop {
  position: fixed;
  bottom: 70px;
  right: 30px;
  z-index: 1000;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #db01d4;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.footer{
  background-color: #232729;
}

.listedata {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-self: start;
  justify-content: center;
  margin: 20px;
}

.cardlist{
  -webkit-box-shadow: 0px 0px 47px 2px rgba(219,1,212,0.18);
  -moz-box-shadow: 0px 0px 47px 2px rgba(219,1,212,0.18);
  box-shadow: 0px 0px 47px 2px rgba(219,1,212,0.18);
}

.listedatamobile {
  height: auto;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.imgcategory{
  width: 110px;
  height: 110px;
  border-radius: 100px;

}

#partner{
  background-color: #ededed;
  margin-bottom: -20px;
}

#aboutme{
  margin-bottom: 20px;
}

.textabout{
  vertical-align: middle;

}

.imgpartner{
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.cardlistpartner{
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 400px;
  margin: 25px;
}

.login{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imagemodal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selectbutton{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}